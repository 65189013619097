.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.image {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}

.schenck {
  /* color:rgb(0, 112, 115) */
  color: rgb(255, 255, 255);
  text-align: center;
}

.sch-bg-color {
  background-color: rgb(0, 112, 115);
  color: rgb(255, 255, 255);
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
/* } */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiInput-underline:after{
  border-bottom-color: rgba(00,70,73,0.5) !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: rgba(00,70,73,.3) !important;
}

/*::-webkit-scrollbar{*/
/*  display: none;*/
/*}*/

body{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-container::-webkit-scrollbar{
  display: none;

}

#root{
  overflow: hidden;
}
